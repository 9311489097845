<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-6">
        <div class="form-group">
          <label>Name<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" type="text" v-model="form.name" placeholder="" />
            <small v-if="errors && errors.name" class="phone_error text-danger">
              {{ errors.name[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Email<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" v-model="form.email" type="email" />
            <small v-if="errors && errors.email" class="text-danger">
              {{ errors.email[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Phone Number<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" v-model="form.phone_number" type="text" />
            <small v-if="errors && errors.phone_number" class="text-danger">
              {{ errors.phone_number[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Category<sup>*</sup> </label>
          <div class="form-group__content">
            <select class="form-control" v-model="form.category_id">
              <option :value="category.id" :key="category.id" v-for="category in categories">{{ category.name }}</option>
            </select>
            <small v-if="errors && errors.category_id" class="text-danger">
              {{ errors.category_id[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Status<sup>*</sup> </label>
          <div class="form-group__content">
            <select class="form-control" v-model="form.status">
              <option value="0">Pending</option>
              <option value="1">Active</option>
            </select>
            <small v-if="errors && errors.status" class="text-danger">
              {{ errors.status[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Description<sup>*</sup> </label>
          <div class="form-group__content">
            <textarea class="form-control" type="text" v-model="form.description"></textarea>
            <small v-if="errors && errors.description" class="phone_error text-danger">
              {{ errors.description[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <button class="ps-btn" @click.prevent="onSubmit()">
            {{ isLoading ? "Submitting..." : "Submit" }}
            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
            <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" v-if="!loading">
          <label>Address</label>
          <!-- <place-form></place-form> -->
          <GmapAutocomplete
              class="form-control"
              v-if="!loadingForm"
              @place_changed="setPlace"
          />
        </div>
        <div class="form-group">
          <label>Suburb</label>
          <input class="form-control" type="text" v-model="form.suburb" placeholder="" />
          <small v-if="errors && errors.suburb" class="text-danger">
            {{ errors.suburb[0] }}
          </small>
        </div>
        <div class="form-group">
          <label>City/Town</label>
          <input class="form-control" type="text" v-model="form.city" placeholder="" />
          <small v-if="errors && errors.city" class="text-danger">
            {{ errors.city[0] }}
          </small>
        </div>
        <div class="form-group">
          <label>Province</label>
          <input class="form-control" type="text" v-model="form.province" placeholder="" />
          <small v-if="errors && errors.province" class="text-danger">
            {{ errors.province[0] }}
          </small>
        </div>
        <div class="form-group">
          <label>Country<sup>*</sup> </label>
          <div class="form-group__content">
            <select class="form-control" v-model="form.country_id">
              <option :value="country.id" v-for="country in countries">
                {{country.name + " (+" + country.zip + ") " }}
              </option>
            </select>
            <small v-if="errors && errors.country_id" class="text-danger">
              {{ errors.country_id[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Postal Code</label>
          <input class="form-control" type="text" v-model="form.postal_code" placeholder="" />
          <small v-if="errors && errors.postal_code" class="text-danger">
            {{ errors.postal_code[0] }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RunnerForm",
  props: {
    runnerId: Number
  },
  data() {
    return {
      runner: null,
      form: {
        category_id: null,
        name: '',
        description: '',
        email: '',
        status: '',
        phone_number: null,
        country_id: null,
        street: '',
        city: '',
        is_default: false,
        address: '',
        suburb: '',
        province: '',
        postal_code: '',
        latitude: '',
        longitude: ''
      },
      errors: null,
      message: null,
      isError: false,
      categories: [],
      countries: [],
      show: true,
      isLoading: false,
      loading: false,
      currentPlace: {},
      loadingForm: false,
    }
  },
  async created() {
    this.getCountries();

    axios.get('runners/categories').then((response) => {
      this.categories = response.data;
    });

    if (this.runnerId) {
      axios.get('runners/' + this.runnerId).then((response) => {
        this.runner = response.data;
        this.form.email = this.runner.user.email;
        this.form.name = this.runner.name;
        this.form.phone_number = this.runner.phone_number;
        this.form.status = this.runner.status;
        this.form.category_id = this.runner.category_id;

        this.form.city = this.runner.address.city;
        this.form.suburb = this.runner.address.suburb;
        this.form.province = this.runner.address.province;
        this.form.country_id = this.runner.address.country_id;
        this.form.town = this.runner.address.town;
        this.form.street = this.runner.address.street;
        this.form.postal_code = this.runner.address.postal_code;

      });
    }
  },
  methods: {

    setPlace(place) {
      this.currentPlace = place;
      const addressComponents = place.address_components
      const countries = this.countries;
      let address = {
        country: null,
        street: place.formatted_address,
        city: '',
        is_default: false,
        address: '',
        suburb: '',
        province: '',
        postal_code: '',
      }
      this.form.latitude = place.geometry.location.lat();
      this.form.longitude = place.geometry.location.lng();

      addressComponents.forEach(function(addressComponent){
        if (addressComponent.types.indexOf('postal_code') >= 0) {
          address.postal_code = addressComponent['long_name'];
        }
        if (addressComponent.types.indexOf("country") >= 0) {
          const country = countries.find(country => country.code.toLowerCase() === addressComponent['short_name'].toLowerCase());
          if (country) {
            address.country = country.id
          }
        }
        if (addressComponent.types.indexOf("administrative_area_level_1") >= 0) {
          address.province = addressComponent['long_name']
        }
        if (addressComponent.types.indexOf("administrative_area_level_2") >= 0) {
          address.city = addressComponent['long_name']
        }
        if (addressComponent.types.indexOf("locality") >= 0) {
          address.town = addressComponent['long_name']
        }
        if (addressComponent.types.indexOf("sublocality_level_2") >= 0) {
          address.suburb = addressComponent['long_name']
        }
        if (addressComponent.types.indexOf("route") >= 0) {
          address.street = addressComponent['long_name'];
        }
        if (addressComponent.types.indexOf("street_number") >= 0) {
          address.street = addressComponent['long_name']
        }
      });

      this.form.city = address.city;
      this.form.suburb = address.suburb;
      this.form.province = address.province;
      this.form.country_id = address.country;
      this.form.town = address.town;
      this.form.street = address.street;
      this.form.postal_code = address.postal_code;
    },

    async getCountries() {
      axios.get(`countries`).then((response) => {
        this.countries = response.data.filter((country) => country.zip && country.code);
      });
    },

    async onSubmit() {
      if (this.runnerId) {
        await this.updateRunner()
      } else {
        await this.createRunner()
      }
    },
    createRunner() {
      this.isLoading = true;
      axios.post('runners/create', this.form).then((response) => {
        this.isLoading = false;
        this.handleSuccess(response, 'Runner saved successfully.', 'admin_runners');
      }).catch((error) => {
        this.isLoading = false;
        this.handleErrors(error, 'Runner could not be created. Please enter all required fields.')
      });
    },
    updateRunner() {
      this.isLoading = true;
      axios.put('runners/' + this.runnerId, this.form).then((response) => {
        this.isLoading = false;
        this.handleSuccess(error, 'Runner updated successfully.');
      }).catch((error) => {
        let message = '';
        this.isLoading = false;
        this.handleErrors(error, 'Runner could not be updated. Please enter all required fields.')
      });
    }
  },
  handleSuccess(message = null) {
    if (message) {
      this.$toasted.success(message);
    }
    this.isLoading = false;
    setTimeout(() => {
      this.$router.push({ name: "admin_runners" });
    }, 1000);
  },

  handleErrors(error, message) {
    if (error.response.status === 422) {
      if (message) {
        this.$toasted.error(message);
      }
      this.errors = error.response.data.errors
    } else {
      this.message = error.backendErrors[0]
    }
    this.isError = true
    this.isLoading = false;
  }
}
</script>

<style scoped>

</style>