<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Runner</h3>
    </div>

    <!-- Content Row -->
    <RunnerForm :runner-id="$route.params.id"></RunnerForm>
  </div>
</template>

<script>
import RunnerForm from "./RunnerForm";
export default {
  name: "EditRunner",
  components: {RunnerForm}
}
</script>

<style scoped>

</style>